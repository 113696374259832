import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "set-up-a-local-environment-with-create-react-app"
    }}>{`Set up a local environment with create-react-app`}</h1>
    <p>{`In the summer of 2016, the React team released a tool called
`}<inlineCode parentName="p">{`create-react-app`}</inlineCode>{`. It creates a React app so that you can get started right
away. All of the code in this course runs with an app set up like this.`}</p>
    <p>{`Getting started with React has never been easier.`}</p>
    <ol>
      <li parentName="ol">{`Make sure you have node.js`}</li>
      <li parentName="ol">{`Install the app generator`}</li>
      <li parentName="ol">{`Run the app generator`}</li>
    </ol>
    <Vimeo id={429501541} mdxType="Vimeo" />
    <h2 {...{
      "id": "make-sure-you-have-nodejs"
    }}>{`Make sure you have node.js`}</h2>
    <p>{`Modern JavaScript development runs on node.js. Your code still ends up in a
browser, but there are a few steps it has to go through before it gets there.
Those toolchains run on node.js.`}</p>
    <p>{`Go to `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/"
      }}>{`nodejs.org`}</a>{`, download one of the latest
versions, and run the installer. You can pick the more stable LTS
(long-term-support) version, or the more gimme-all-the-features version.
JavaScript toolchains run fine in both.`}</p>
    <p>{`Server-side rendering might be easier with the fancy-pants version. More on
that later.`}</p>
    <h2 {...{
      "id": "install-create-react-app"
    }}>{`Install create-react-app`}</h2>
    <p>{`Great, you have node and you're ready to go.`}</p>
    <p>{`Run this command in a terminal:`}</p>
    <pre><code parentName="pre" {...{}}>{`$ npm install -g create-react-app
`}</code></pre>
    <p>{`This installs a global npm package called `}<inlineCode parentName="p">{`create-react-app`}</inlineCode>{`. Its job is to
create a directory and install `}<inlineCode parentName="p">{`react-scripts`}</inlineCode>{`.`}</p>
    <p>{`Confusing, I know.`}</p>
    <p>{`You can think of `}<inlineCode parentName="p">{`create-react-app`}</inlineCode>{` and `}<inlineCode parentName="p">{`react-scripts`}</inlineCode>{` as two parts of the
same construction. The first is a lightweight script that you can install
globally and never update; the second is where the work happens. You want this
one to be fresh every time you start a new app.`}</p>
    <p>{`Keeping a global dependency up to date would suck, especially considering there
have been six major updates since Facebook first launched `}<inlineCode parentName="p">{`create-react-app`}</inlineCode>{` in
July 2016.`}</p>
    <h2 {...{
      "id": "run-create-react-app"
    }}>{`Run create-react-app`}</h2>
    <p>{`Superb! You have `}<inlineCode parentName="p">{`create-react-app`}</inlineCode>{`. Time to create an app and get started with
some coding.`}</p>
    <p>{`Run this in a terminal:`}</p>
    <pre><code parentName="pre" {...{}}>{`$ create-react-app reactdataviz-project
`}</code></pre>
    <p>{`Congratulations! You just created a React app. `}<em parentName="p">{`With`}</em>{` all the setup and the
fuss that goes into using a modern JavaScript toolchain.`}</p>
    <p>{`Your next step is to run your app:`}</p>
    <pre><code parentName="pre" {...{}}>{`$ cd reactdataviz-project
$ npm start
`}</code></pre>
    <p>{`A browser tab should open with a blank page and a spinning React logo.`}</p>
    <p>{`If that didn't work, then something must have gone terribly wrong. You should
consult
`}<a parentName="p" {...{
        "href": "https://github.com/facebookincubator/create-react-app"
      }}>{`the official docs`}</a>{`.
Maybe that will help.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      